import { FC, ReactNode } from 'react'
import Typography from '~/components/Typography/Typography'
import BubbleWrap from '~/components/Map/PlaceMarker/BubbleWrap'
import Box from '~/components/Box/Box'
import { SystemProps, Theme } from '@xstyled/styled-components'
import { Color, SystemProp } from '@xstyled/system'

interface BubbleContentProps {
  headerText: string
  color: string
  backgroundColor?: string
  fontColor?: SystemProp<Color, Theme>
  width?: SystemProps['w']
  headerContent?: ReactNode
  content?: ReactNode
  leadingIcon?: ReactNode
}
const BubbleContent: FC<BubbleContentProps> = ({
  headerText,
  width,
  color,
  backgroundColor = 'system-white',
  fontColor = 'gray-800',
  headerContent,
  content,
  leadingIcon,
}) => {
  return (
    <BubbleWrap width={width ?? '100%'} color={color}>
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          width="100%"
          display="flex"
          padding="7px"
          justifyContent="center"
          alignItems="center"
          gap="3px"
          backgroundColor={backgroundColor}
        >
          {leadingIcon && <Box>{leadingIcon}</Box>}
          <Typography
            w="fit-content"
            textAlign="center"
            variant="caption1"
            whiteSpace="nowrap"
            overflowX="hidden"
            textOverflow="ellipsis"
            color={fontColor}
            fontWeight="600"
            lineHeight="14px"
          >
            {headerText}
          </Typography>
          {headerContent}
        </Box>
        {content && (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
            {content}
          </Box>
        )}
      </Box>
    </BubbleWrap>
  )
}

export default BubbleContent
