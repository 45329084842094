/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */

export type RegionGubun = typeof RegionGubun[keyof typeof RegionGubun];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegionGubun = {
  sido: 'sido',
  si: 'si',
  sigungu: 'sigungu',
  dong: 'dong',
  li: 'li',
} as const;
