import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { MapMarkerWrapper, Offset, Position } from '@mint-ui/map'
import Box from '~/components/Box/Box'
import ContentMintMap from '~/components/Map/ContentMintMap'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { type TenantsAggregationsDetailsOut } from '~/libs/apis/data/model'
import ClientDetailMapMarker from '~/templates/common/detail/ClientDetailMapMarker'
import { PLACE_MARKER_ANCHOR_X, UNDEFINED_ANCHOR_Y } from '~/libs/constants/place'

interface OccupancyStatusMapProps {
  activeIndex: number | undefined
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>
  list?: TenantsAggregationsDetailsOut[]
}

const MARKER_Z_INDICES = {
  NEARBY_BUILDING: 1,
  ACTIVE_BUILDING: 2,
}

const OccupancyStatusMap: FC<OccupancyStatusMapProps> = ({ list, activeIndex, setActiveIndex }) => {
  const { showOffice, showLwh } = useFullDetailDialog()

  const position = list?.map((item) => new Position(item.lat, item.lng))
  const mapRef = useRef<HTMLDivElement>(null)
  const anchor = new Offset(PLACE_MARKER_ANCHOR_X, UNDEFINED_ANCHOR_Y)

  const handleMouseOver = (index: number) => () => setActiveIndex(index)
  const handleMouseOut = () => setActiveIndex(undefined)
  const createClickHandler = (item: TenantsAggregationsDetailsOut) => () => {
    if (item.propertyTypeCode === 'PT01_1') {
      showOffice(item.raId)
    } else {
      showLwh(item.raId)
    }
  }

  return (
    <Box
      ref={mapRef}
      key={list?.map((item) => item.raId).toString()}
      borderRadius="10px"
      height="400px"
      overflow="hidden"
    >
      <ContentMintMap focusBoundary={position}>
        {list?.map((item, index) => (
          <MapMarkerWrapper key={item.raId} anchor={anchor} position={{ lat: item.lat, lng: item.lng }}>
            <Box
              position="relative"
              width="24px"
              height="30px"
              onMouseOver={handleMouseOver(index)}
              onMouseOut={handleMouseOut}
              onClick={createClickHandler(item)}
              zIndex={
                activeIndex === index ? MARKER_Z_INDICES.ACTIVE_BUILDING : MARKER_Z_INDICES.NEARBY_BUILDING - index
              }
            >
              <Box position="absolute" left="-4px" bottom="0">
                <ClientDetailMapMarker buildingName={item.buildingName} isMain={activeIndex === index} />
              </Box>
            </Box>
          </MapMarkerWrapper>
        ))}
      </ContentMintMap>
    </Box>
  )
}

export default OccupancyStatusMap
