/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */

export type BuildingsOrderByGroup = typeof BuildingsOrderByGroup[keyof typeof BuildingsOrderByGroup];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BuildingsOrderByGroup = {
  rsquareRecommend: 'rsquareRecommend',
  totalAreaDesc: 'totalAreaDesc',
  totalAreaAsc: 'totalAreaAsc',
  constructDateDesc: 'constructDateDesc',
  constructDateAsc: 'constructDateAsc',
  rentFeePerM2Desc: 'rentFeePerM2Desc',
  rentFeePerM2Asc: 'rentFeePerM2Asc',
  tradePricePerM2Desc: 'tradePricePerM2Desc',
  tradePricePerM2Asc: 'tradePricePerM2Asc',
  tradeDateDesc: 'tradeDateDesc',
} as const;
