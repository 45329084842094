import { FC, PropsWithChildren } from 'react'
import Box from '~/components/Box/Box'
import Tail from '~/assets/images/marker/tail.svg'
import { SystemProps } from '@xstyled/styled-components'

interface BubbleWrapProps {
  color: string
  width?: SystemProps['w']
}

const BubbleWrap: FC<PropsWithChildren<BubbleWrapProps>> = ({ color, width, children }) => {
  return (
    <Box width={width ?? 'fit-content'} cursor="pointer">
      <Box
        className="bubble-children"
        display="flex"
        overflow="hidden"
        backgroundColor={color}
        borderColor={color}
        borderWidth="1px"
        borderRadius="10px"
        boxShadow="marker-01"
      >
        {children}
      </Box>
      <Box ml="10px">
        <Box className="tail-icon" as={Tail} width="12px" height="9px" marginTop="-1px" color={color} />
      </Box>
    </Box>
  )
}

export default BubbleWrap
