/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */

export type RegistrationsOrder = typeof RegistrationsOrder[keyof typeof RegistrationsOrder];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationsOrder = {
  registrationCompletedDate: 'registrationCompletedDate',
  '-registrationCompletedDate': '-registrationCompletedDate',
  registrationAddress: 'registrationAddress',
  '-registrationAddress': '-registrationAddress',
  registrationReceiptDate: 'registrationReceiptDate',
  '-registrationReceiptDate': '-registrationReceiptDate',
  '-registrationChangeDate': '-registrationChangeDate',
} as const;
