/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */

export type TenantsStatusCompareType = typeof TenantsStatusCompareType[keyof typeof TenantsStatusCompareType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TenantsStatusCompareType = {
  client_sector: 'client_sector',
  company_type: 'company_type',
} as const;
