import React, { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
} from '~/libs/apis/data/gov/gov'
import Box from '~/components/Box/Box'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import LedgerInfoTable from '~/templates/place/detail/building/LedgerInfoTable'
import TabButton from '~/components/TabButton/TabButton'
import ScrollableTabButtonBox from '~/components/TabButton/ScrollableTabButtonBox'
import Typography from '~/components/Typography/Typography'
import { commonDatas } from '~/templates/place/detail/building/BuildingInfo'
import Button from '~/components/Button/Button'
import { isNotNil } from '@toss/utils'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'

interface LandDetailTableProps {
  pnu: string
}

const LandDetailTable: FC<LandDetailTableProps> = ({ pnu }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: msg } = useTranslation('common', { keyPrefix: 'common_msg' })
  const { t: detail } = useTranslation('common', { keyPrefix: 'detail.common_data' })
  const { data: ledger } = useLedgerOutlineDataV1GovLedgerOutlineGet({ pnu: pnu })
  const { data: ledgerSummary } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu },
    { swr: { shouldRetryOnError: false } },
  )
  const { uid } = useDetailInfo()
  const hasPnu = isNotNil(pnu)
  const { showLand } = useFullDetailDialog()
  const [selected, setSelected] = useState<string>('0')

  if (!ledger?.data) {
    return <SkeletonComponent width={345} height={180} />
  }

  const hasBuilding = ledger.data.length !== 0
  const CommonDataButtonGroup = () => {
    return (
      <Box display="flex" gap="9px">
        {commonDatas.map((item) => (
          <Button
            key={item}
            size="md"
            variant="filled"
            color="gray"
            content={detail(item)}
            width="100%"
            disabled={item === 'ledger' ? !hasBuilding : !hasPnu}
            onClick={() => {
              showLand(uid, item)
            }}
          />
        ))}
      </Box>
    )
  }

  if (!hasBuilding) {
    return (
      <>
        <Box display="flex" justifyContent="center" backgroundColor="gray-100" borderRadius={10} py={10}>
          <Typography fontWeight="semibold" color="gray-500">
            {msg('no_building_information')}
          </Typography>
        </Box>
        <CommonDataButtonGroup />
      </>
    )
  }

  const ledgerSummaryItem = {
    title: t('total_ledger'),
    content: ledgerSummary && <LedgerInfoTable data={ledgerSummary} />,
  }
  const ledgerItems = ledger.data.map((data) => ({
    title: data.dongName ? `${t('ledger')}(${data.dongName})` : t('ledger'),
    content: <LedgerInfoTable key={data.mgmBldrgstPk} data={data} />,
  }))
  const combinedLedgerItems = ledgerSummary ? [ledgerSummaryItem, ...ledgerItems] : [...ledgerItems]

  const tabItems = combinedLedgerItems.map((item, index) => ({
    text: item.title,
    value: index.toString(),
  }))
  const selectedContent = combinedLedgerItems[Number(selected)]?.content
  const hasMultipleTabs = combinedLedgerItems.length > 1

  const handleTabChange = (value: string) => {
    setSelected(value)
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4}>
        {hasMultipleTabs ? (
          <>
            <ScrollableTabButtonBox>
              <TabButton items={tabItems} size="sm" onSelect={handleTabChange} selected={selected} />
            </ScrollableTabButtonBox>
            {selectedContent}
          </>
        ) : (
          <LedgerInfoTable data={ledger.data[0]} />
        )}
      </Box>
      <CommonDataButtonGroup />
    </>
  )
}

export default LandDetailTable
