import { FC, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { type GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams } from '~/libs/apis/data/model'
import NoticeBox from '~/components/NoticeBox/NoticeBox'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import { commaizeNumber } from '@toss/utils'
import { useGetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet } from '~/libs/apis/data/clients/clients'
import RegistrationOwnershipMap from '~/templates/common/detail/RegistrationOwnershipMap'
import RegistrationOwnershipGrid from '~/templates/common/detail/RegistrationOwnershipGrid'
import NoData from '~/components/NoData'

interface RegistrationOwnershipProps {
  clientIds: string
}

const PAGE_SIZE = 10

const RegistrationOwnership: FC<RegistrationOwnershipProps> = ({ clientIds }) => {
  const { t } = useTranslation('common', { keyPrefix: 'client' })
  const { lastUpdate } = useLastUpdateDate()
  const [activeIndex, setActiveIndex] = useState<number | undefined>()

  const [params, setParams] =
    useState<GetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGetParams>({
      pageNum: 1,
      pageSize: PAGE_SIZE,
    })
  const { data: detail } = useGetClientRegistrationsOwnershipDataV1ClientsClientIdRegistrationsOwnershipGet(
    clientIds,
    {
      ...params,
    },
    { swr: { keepPreviousData: true, enabled: Boolean(clientIds) } },
  )

  const page = detail?.page
  const pagerModel = page
    ? {
        pageSize: page.pageSize,
        pageCount: page.totalPage,
        currentPage: page.page,
        totalRecordsCount: page.totalSize,
      }
    : undefined
  const count = page?.totalSize

  const handlePageChange = (object: { currentPage: number }) =>
    setParams((prev) => ({ ...prev, pageNum: object.currentPage }))

  const noData = !detail?.data || detail?.data?.length === 0

  return (
    <Box display="flex" flexDirection="column" p="24px 20px" gap="20px">
      <Typography variant="h5" fontWeight="semibold" color="gray-800" as="span">
        <Trans
          i18nKey="client.registration_ownership"
          values={{ number: commaizeNumber(count ?? 0) }}
          components={{
            color: <Typography variant="h5" fontWeight="semibold" color="purple-700" as="span" />,
          }}
        />
      </Typography>
      {noData ? (
        <NoData height={430} />
      ) : (
        <>
          <NoticeBox
            type="normal"
            content={<Typography>{t('message.registration_ownership_information', { yq: lastUpdate.yq })}</Typography>}
          />
          <RegistrationOwnershipMap
            key={clientIds}
            list={detail?.data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <RegistrationOwnershipGrid
            dataSource={detail?.data}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            pagerModel={pagerModel}
            page={page}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Box>
  )
}

export default RegistrationOwnership
