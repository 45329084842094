/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  GetRegistrationsClusterDataV1RegistrationsClusterGetParams
} from '../model/getRegistrationsClusterDataV1RegistrationsClusterGetParams'
import type {
  GetRegistrationsCntDataV1RegistrationsCountGetParams
} from '../model/getRegistrationsCntDataV1RegistrationsCountGetParams'
import type {
  GetRegistrationsDataV1RegistrationsGetParams
} from '../model/getRegistrationsDataV1RegistrationsGetParams'
import type {
  GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams
} from '../model/getRegistrationsMarkerDataV1RegistrationsMarkerGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  RegistrationClusterOut
} from '../model/registrationClusterOut'
import type {
  RegistrationMarkerOut
} from '../model/registrationMarkerOut'
import type {
  RegistrationsCountOut
} from '../model/registrationsCountOut'
import type {
  RegistrationsPagingOut
} from '../model/registrationsPagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 등기 리스트
 */
export const getRegistrationsDataV1RegistrationsGet = (
    params?: GetRegistrationsDataV1RegistrationsGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationsPagingOut>(
      {url: `/data/v1/registrations`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsDataV1RegistrationsGetKey = (params?: GetRegistrationsDataV1RegistrationsGetParams,) => [`/data/v1/registrations`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsDataV1RegistrationsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsDataV1RegistrationsGet>>>
export type GetRegistrationsDataV1RegistrationsGetQueryError = HTTPValidationError

/**
 * @summary 등기 리스트
 */
export const useGetRegistrationsDataV1RegistrationsGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsDataV1RegistrationsGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsDataV1RegistrationsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsDataV1RegistrationsGetKey(params) : null);
  const swrFn = () => getRegistrationsDataV1RegistrationsGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 리스트 카운트
 */
export const getRegistrationsCntDataV1RegistrationsCountGet = (
    params?: GetRegistrationsCntDataV1RegistrationsCountGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationsCountOut>(
      {url: `/data/v1/registrations/count`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsCntDataV1RegistrationsCountGetKey = (params?: GetRegistrationsCntDataV1RegistrationsCountGetParams,) => [`/data/v1/registrations/count`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsCntDataV1RegistrationsCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsCntDataV1RegistrationsCountGet>>>
export type GetRegistrationsCntDataV1RegistrationsCountGetQueryError = HTTPValidationError

/**
 * @summary 등기 리스트 카운트
 */
export const useGetRegistrationsCntDataV1RegistrationsCountGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsCntDataV1RegistrationsCountGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsCntDataV1RegistrationsCountGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsCntDataV1RegistrationsCountGetKey(params) : null);
  const swrFn = () => getRegistrationsCntDataV1RegistrationsCountGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 리스트
 */
export const getRegistrationsClusterDataV1RegistrationsClusterGet = (
    params: GetRegistrationsClusterDataV1RegistrationsClusterGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationClusterOut[]>(
      {url: `/data/v1/registrations/cluster`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsClusterDataV1RegistrationsClusterGetKey = (params: GetRegistrationsClusterDataV1RegistrationsClusterGetParams,) => [`/data/v1/registrations/cluster`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsClusterDataV1RegistrationsClusterGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsClusterDataV1RegistrationsClusterGet>>>
export type GetRegistrationsClusterDataV1RegistrationsClusterGetQueryError = HTTPValidationError

/**
 * @summary 등기 리스트
 */
export const useGetRegistrationsClusterDataV1RegistrationsClusterGet = <TError = HTTPValidationError>(
 params: GetRegistrationsClusterDataV1RegistrationsClusterGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsClusterDataV1RegistrationsClusterGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsClusterDataV1RegistrationsClusterGetKey(params) : null);
  const swrFn = () => getRegistrationsClusterDataV1RegistrationsClusterGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 마커 리스트
 */
export const getRegistrationsMarkerDataV1RegistrationsMarkerGet = (
    params?: GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegistrationMarkerOut[]>(
      {url: `/data/v1/registrations/marker`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRegistrationsMarkerDataV1RegistrationsMarkerGetKey = (params?: GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams,) => [`/data/v1/registrations/marker`, ...(params ? [params]: [])] as const;

    
export type GetRegistrationsMarkerDataV1RegistrationsMarkerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationsMarkerDataV1RegistrationsMarkerGet>>>
export type GetRegistrationsMarkerDataV1RegistrationsMarkerGetQueryError = HTTPValidationError

/**
 * @summary 등기 마커 리스트
 */
export const useGetRegistrationsMarkerDataV1RegistrationsMarkerGet = <TError = HTTPValidationError>(
 params?: GetRegistrationsMarkerDataV1RegistrationsMarkerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRegistrationsMarkerDataV1RegistrationsMarkerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRegistrationsMarkerDataV1RegistrationsMarkerGetKey(params) : null);
  const swrFn = () => getRegistrationsMarkerDataV1RegistrationsMarkerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

