import { FC, useState } from 'react'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import LesseeInfoGrid from '~/templates/place/detail/lessee/LesseeInfoGrid'
import { useLwhLesseeDataV1BuildingsLwhLesseeGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'

const PAGE_SIZE = 5
const LwhLesseeInfo: FC = () => {
  const { uid: raId } = useDetailInfo()

  const [pageNum, setPageNum] = useState(1)

  const { lastUpdateParams } = useLastUpdateDate('quarter')
  const { data, isLoading } = useLwhLesseeDataV1BuildingsLwhLesseeGet(
    { raId, pageNum, pageSize: PAGE_SIZE, year: lastUpdateParams.year, quarter: lastUpdateParams.quarter! },
    {
      swr: { keepPreviousData: true },
    },
  )

  const handlePageChange = (page: number) => setPageNum(page)

  return (
    <LesseeInfoGrid
      dataSource={data?.data}
      pageModel={data?.page}
      onPageChange={handlePageChange}
      isLoading={isLoading}
    />
  )
}

export default LwhLesseeInfo
