import {
  SchemasMarketAnalysisLwhLeaseTrendBoeDetailOut,
  SchemasMarketAnalysisOfficeLeaseTrendBoeDetailOut,
  TrendBoeOut,
  TrendBuildingSizeOut,
} from '~/libs/apis/data/model'
import { getDateByYearAndQuarter } from '~/libs/utils/chart'

export const OFFICE_LEASE_TREND_INDICATORS_OPTIONS = [
  {
    id: 'rentFeePerPy',
    labelKey: 'common:common_term.rent_fee',
    checked: true,
    fill: 'chart-7',
  },
  {
    id: 'noc',
    labelKey: 'common:common_term.noc',
    checked: true,
    divider: true,
    fill: 'chart-8',
  },
  {
    id: 'emptyRate',
    labelKey: 'common:common_term.empty_rate_newest',
    checked: true,
    divider: true,
    fill: 'chart-9',
  },
  {
    id: 'emptyRateExcludeNewest',
    labelKey: 'common:common_term.empty_rate_exclude_newest',
    checked: false,
    fill: 'chart-10',
  },
] as const

export type OFFICE_LEASE_TREND_ID = (typeof OFFICE_LEASE_TREND_INDICATORS_OPTIONS)[number]['id']

export const LWH_LEASE_MINIMUM_YEAR_QUARTER = {
  year: '2022',
  quarter: 'Q4',
} as const

export const LWH_LEASE_TREND_INDICATORS_OPTIONS = [
  {
    id: 'roomRentFeePerPy',
    labelKey: 'common:common_term.room_rent_fee',
    checked: true,
    fill: 'chart-1',
  },
  {
    id: 'lowRentFeePerPy',
    labelKey: 'common:common_term.low_rent_fee',
    checked: true,
    fill: 'chart-2',
  },
  {
    id: 'roomEmptyRate',
    labelKey: 'common:common_term.room_empty_rate_newest',
    checked: true,
    divider: true,
    fill: 'chart-7',
  },
  {
    id: 'roomEmptyRateExcludeNewest',
    labelKey: 'common:common_term.room_empty_rate_exclude_newest',
    checked: false,
    fill: 'chart-6',
  },
  {
    id: 'lowEmptyRate',
    labelKey: 'common:common_term.low_empty_rate_newest',
    checked: true,
    divider: true,
    fill: 'chart-5',
  },
  {
    id: 'lowEmptyRateExcludeNewest',
    labelKey: 'common:common_term.low_empty_rate_exclude_newest',
    checked: false,
    fill: 'chart-12',
  },
] as const

export type LWH_LEASE_TREND_ID = (typeof LWH_LEASE_TREND_INDICATORS_OPTIONS)[number]['id']

export interface MarketRentMarketByBoeChartProps {
  type: 'office' | 'lwh'
  indicatorIds: string[]
  isLoading: boolean
  data:
    | SchemasMarketAnalysisOfficeLeaseTrendBoeDetailOut[]
    | SchemasMarketAnalysisLwhLeaseTrendBoeDetailOut[]
    | undefined
}

export const handleCommonChartAndGridCommonFormat = (
  data: TrendBoeOut[] | TrendBuildingSizeOut[] | undefined,
  comparisonType: 'boe' | 'size',
  filterCodes: string[] | undefined,
) => {
  const codeIndexKey = comparisonType === 'boe' ? 'boeTypeCode' : 'buildingSizeCode'
  const formatted =
    data?.map((item) => {
      const value = item.value

      return {
        ...item,
        value: value,
        fullDate: getDateByYearAndQuarter(item.year, item.quarter!),
        tooltipTitle: `${item.year}-${item.quarter}`,
        code: codeIndexKey in item ? (item[codeIndexKey as keyof typeof item] as string) : '',
      }
    }) ?? []

  return formatted.filter((item) => filterCodes?.includes(item.code))
}
