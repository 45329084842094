import { SUBWAY_LINE_ID } from '~/libs/constants/common'
import { useTranslation } from 'next-i18next'
import { isNotNil } from '@toss/utils'
import { formatOnes } from '~/libs/utils/format'
import { SystemProps } from '@xstyled/styled-components'

const CapitalRegionColor: Record<string, SystemProps['color']> = {
  line1: '#0C318A',
  line2: '#51A635',
  line3: '#E96537',
  line4: '#3567C8',
  line5: '#753EA9',
  line6: '#87481B',
  line7: '#586016',
  line8: '#D13663',
  line9: '#B3942E',
  incheon_line1: '#2E6BEA',
  incheon_line2: '#F5B253',
  airport_railroad: '#72ADDE',
  gyeongui_central: '#80B99C',
  everline: '#85C373',
  gyeongchun: '#459B75',
  shinbundang: '#92202A',
  lrt_uijeongbu: '#F18926',
  gyeonggang: '#2E6BEA',
  lrt_uisinseol: '#BFB72C',
  sso: '#8CB945',
  gimpo_goldline: '#87661D',
  suinbundang: '#E2AA31',
  lrt_sillim: '#0C318A',
}

const DaejeonColor: Record<string, SystemProps['color']> = {
  daejeon_line1: '#51A635',
}

const DaeguColor: Record<string, SystemProps['color']> = {
  daegu_line1: '#E76A3E',
  daegu_line2: '#51A635',
  daegu_line3: '#F5BA59',
}
const GwangjuColor: Record<string, SystemProps['color']> = {
  gwangju_line1: '#51A635',
}
const BusanColor: Record<string, SystemProps['color']> = {
  busan_line1: '#E76A3E',
  busan_line2: '#51A635',
  busan_line3: '#CBA361',
  busan_line4: '#2F66D1',
  dong_hae: '#7B9ED0',
  gimhae_light_rail: '#994CC1',
}

const SubwayLineCodeMap: Record<string, Record<string, SystemProps['color']>> = {
  '1000': CapitalRegionColor,
  '3000': DaejeonColor,
  '4000': DaeguColor,
  '5000': GwangjuColor,
  '7000': BusanColor,
}

const useSubwayUtil = () => {
  const { t } = useTranslation()

  const getSubwayLineColor = (serviceAreaCode: NullishString, lineCode: NullishString) => {
    if (!isNotNil(serviceAreaCode) || !isNotNil(lineCode)) {
      return null
    }

    if (!Object.keys(SUBWAY_LINE_ID).includes(serviceAreaCode)) {
      return null
    }

    const idMap = SUBWAY_LINE_ID[serviceAreaCode]
    const colorMap = SubwayLineCodeMap[serviceAreaCode]

    const id = idMap[lineCode]
    const subwayColor = colorMap[id]

    return subwayColor ?? null
  }

  const getSubwayLineName = (serviceAreaCode: NullishString, lineCode: NullishString) => {
    if (!isNotNil(serviceAreaCode) || !isNotNil(lineCode)) {
      return null
    }

    if (!Object.keys(SUBWAY_LINE_ID).includes(serviceAreaCode)) {
      return null
    }

    const idMap = SUBWAY_LINE_ID[serviceAreaCode]
    const id = idMap[lineCode]

    return t(`common:subway.${id}`)
  }

  const getSubwayStationWalkingInfo = (
    station?: NullishString,
    walkingDistance?: NullishNumber,
    walkingTime?: NullishNumber,
  ) => {
    if (!isNotNil(station) && !isNotNil(walkingDistance) && !isNotNil(walkingTime)) {
      return '-'
    }
    const subwayStation = station ?? ''
    const subwayDistance = walkingDistance ? ` ${formatOnes(walkingDistance)}m` : ''
    const subwayWalkingTime = walkingTime ? ` (${walkingTime}${t('common:common_term.minute')})` : ''

    return `${subwayStation}${subwayDistance}${subwayWalkingTime}`
  }

  return { getSubwayLineColor, getSubwayLineName, getSubwayStationWalkingInfo }
}

export default useSubwayUtil
