import { datadogRum } from '@datadog/browser-rum'
import { sendGTMEvent } from '@next/third-parties/google'
import { useCallbackOnce } from '@toss/react'
import { isNotNil } from '@toss/utils'
import { millisecondsInHour } from 'date-fns/constants'
import { FC, PropsWithChildren, useEffect, useRef } from 'react'
import { clearInterval, setInterval } from 'worker-timers'
import Box from '~/components/Box/Box'
import Indicator from '~/components/Indicator/Indicator'
import { useCommonCodesDataV1CommonCodesGet, useGetExchangesDataV1ExchangesGet } from '~/libs/apis/data/default/default'
import { useGetR3CommonCode } from '~/libs/apis/registration/api'
import {
  patchUsersToken,
  useGetUserConfiguration,
  useGetUserMe,
  useGetUserSubscriptionsPermissions,
  useGetUsersVerifyToken,
} from '~/libs/apis/service/api'
import { HEADER_HEIGHT, SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'
import RaServiceError from '~/libs/errors/RaServiceError'
import useAuth from '~/libs/hooks/useAuth'
import useDataVersion from '~/libs/hooks/useDataVersion'
import usePermissionEffect from '~/libs/hooks/usePermissionEffect'
import useRetainSession from '~/libs/hooks/useRetainSession'
import { getAuthData, setAuthData } from '~/libs/utils/auth'
import { bootChannel } from '~/libs/utils/channel'
import { setAccessToken } from '~/libs/utils/customInstance'
import { setDatadogRumUser } from '~/libs/utils/rum'
import ConfigurationContextProvider from '~/templates/ConfigurationContextProvider'
import ConfigurationDialog from '~/templates/ConfigurationDialog'
import DowntimeNoticeDialog from '~/templates/DowntimeNoticeDialog'
import DowntimeOverlay from '~/templates/DowntimeOverlay'
import FullDetailContextProvider from '~/templates/FullDetailDialogProvider'
import Header from '~/templates/Header'
import InquiryContextProvider from '~/templates/InquiryContextProvider'
import NoticeDialog from '~/templates/NoticeDialog'
import PdfDialogContextProvider from '~/templates/PdfDialogContextProvider'
import Toast from '~/templates/Toast'
import ToastContextProvider from '~/templates/ToastContextProvider'
import InquiryDialog from '~/templates/place/inquiry/InquiryDialog'

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const { logout } = useAuth()
  const { logoutAlert } = useRetainSession()
  const { data: me, isLoading: isMeLoading, error: meError } = useGetUserMe()
  const { isLoading: isConfigurationLoading } = useGetUserConfiguration()
  const { isLoading: isExchangesLoading } = useGetExchangesDataV1ExchangesGet()
  const { data: version, isLoading: isVersionLoading, error: versionError } = useDataVersion()
  const { isLoading: isCommonDataLoading } = useCommonCodesDataV1CommonCodesGet(SWR_IMMUTABLE_OPTION)
  const { isLoading: isR3CommonCodeLoading } = useGetR3CommonCode(undefined, SWR_IMMUTABLE_OPTION)
  const { isLoading: isUserSubscriptionsPermissionsLoading } = useGetUserSubscriptionsPermissions(SWR_IMMUTABLE_OPTION)
  const refreshTokenIntervalId = useRef<number>()

  const callRefreshToken = () => {
    const token = getAuthData()
    if (!token) {
      logout()
      return
    }
    patchUsersToken({ refreshToken: token?.refreshToken })
      .then((data) => {
        setAuthData({ ...token, ...data })
        setAccessToken(data)
      })
      .catch(() => {
        logout()
      })
  }

  const setRefreshTokenInterval = useCallbackOnce(() => {
    refreshTokenIntervalId.current = setInterval(callRefreshToken, millisecondsInHour)
  }, [])

  useEffect(() => {
    setRefreshTokenInterval()
    return () => {
      try {
        if (isNotNil(refreshTokenIntervalId.current)) {
          clearInterval(refreshTokenIntervalId.current)
          refreshTokenIntervalId.current = undefined
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'production') {
          datadogRum.addError(error)
        }
      }
    }
  }, [setRefreshTokenInterval])

  useEffect(() => {
    if (me) {
      const monitorId = `${me.raBusinessPartnerId}_${me.raUserId}`
      if (process.env.GTM_ID) {
        sendGTMEvent({ userId: monitorId })
      }

      bootChannel(monitorId, {
        name: me.userName,
        email: me.email,
        phone: me.phone,
        businessPartnerName: me.businessPartnerName,
        teamName: me.teamName,
      })
      setDatadogRumUser(monitorId)
    }
  }, [me])

  useEffect(() => {
    if (version?.data && version?.data !== localStorage.getItem('dataVersion')) {
      localStorage.setItem('dataVersion', version.data)
    }
  }, [version])

  const { error } = useGetUsersVerifyToken({
    swr: { revalidateOnFocus: true, shouldRetryOnError: false },
  })

  useEffect(() => {
    // 토큰이 이미 만료된 케이스는 로그아웃처리나 팝업을 띄울 필요가 없음
    // 실제로 넘어오는 error type은 명시된 ExceptionResponse가 아니라 RaAdminError로 넘어옴
    // noinspection SuspiciousTypeOfGuard
    if (error && error instanceof RaServiceError) {
      const errorCode = error.responseBody.code

      /*
       * 토큰이 없거나, 만료된 케이스에 대해서는 알람을 띄우지 않고, 로그인 후 해당 페이지로 돌아올 수 있도록 처리
       * 40004: rsquare-account에서 넘어온 에러 메시지
       * 41000: 토큰이 존재하지 않을 때
       * 41001: 토큰이 만료되었을 때
       */
      if (errorCode === '40004' || errorCode === '41000' || errorCode === '41001') {
        logout(true)
      } else {
        logoutAlert(error.responseBody)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  usePermissionEffect()

  if (
    isMeLoading ||
    meError ||
    isVersionLoading ||
    versionError ||
    isCommonDataLoading ||
    isConfigurationLoading ||
    isExchangesLoading ||
    isR3CommonCodeLoading ||
    isUserSubscriptionsPermissionsLoading
  ) {
    return <Indicator loading />
  }

  return (
    <div id="dialog-target">
      <ToastContextProvider>
        <InquiryContextProvider>
          <PdfDialogContextProvider>
            <FullDetailContextProvider>
              <ConfigurationContextProvider>
                <Header />
                <Box as="main" paddingTop={HEADER_HEIGHT} height="100vh">
                  {children}
                </Box>
                <InquiryDialog />
                <ConfigurationDialog />
                <Toast />
              </ConfigurationContextProvider>
            </FullDetailContextProvider>
          </PdfDialogContextProvider>
        </InquiryContextProvider>
      </ToastContextProvider>
      <DowntimeNoticeDialog />
      <NoticeDialog />
      <DowntimeOverlay />
    </div>
  )
}

export default DefaultLayout
