import { FC } from 'react'
import BubbleWrap from '~/components/Map/PlaceMarker/BubbleWrap'
import Typography from '~/components/Typography/Typography'

interface AddressMarkerProps {
  address: string
  isActive?: boolean
}

const ColorTemplate = {
  normal: 'blue-600',
  active: 'blue-700',
}
const AddressMarker: FC<AddressMarkerProps> = ({ address, isActive }) => {
  return (
    <BubbleWrap color={!isActive ? ColorTemplate.normal : ColorTemplate.active}>
      <Typography
        variant="caption1"
        fontWeight="600"
        padding="6px 10px"
        whiteSpace="nowrap"
        backgroundColor="system-white"
      >
        {address}
      </Typography>
    </BubbleWrap>
  )
}

// eslint-disable-next-line import/no-unused-modules
export default AddressMarker
