import { ColumnModel } from '@syncfusion/ej2-react-grids'
import { useTranslation } from 'next-i18next'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { formatVacancyRate } from '~/libs/utils/format'
import { usePerUnitFormat, usePerUnitValueAccessor } from '~/libs/hooks/useFormat'
import { FC, useMemo, useState } from 'react'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import MultiTemplate from '~/components/DataGrid/MultiTemplate'
import useCommonCode from '~/libs/hooks/useCommonCode'
import useCodeNameValueAccessor from '~/libs/hooks/useCodeNameValueAccessor'
import { useGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import { isNotNil } from '@toss/utils'

const PAGE_SIZE = 5

interface LwhLeasePrdHistoryGridProps {
  uid: string
}

const LwhLeasePrdHistoryGrid: FC<LwhLeasePrdHistoryGridProps> = ({ uid }) => {
  const [pageNum, setPageNum] = useState(1)
  const { detailType } = useDetailInfo()
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const isFull = detailType === 'full'
  const isKrw = currencyUnit === 'KRW'
  const unitFormatter = usePerUnitFormat()
  const perUnitValueAccessor = usePerUnitValueAccessor(areaUnit, currencyUnit)
  const { codeToName } = useCommonCode()
  const codeNameValueAccessor = useCodeNameValueAccessor()

  const { data, isLoading } = useGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet(
    uid,
    {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
    },
    {
      swr: {
        keepPreviousData: true,
        enabled: isNotNil(uid),
      },
    },
  )

  const dataSource = useMemo(
    () =>
      data?.data.map((item) => {
        return {
          depositFeePerM2: item.depositFeePerM2,
          lwhTypeCode: item.lwhTypeCode,
          maintenanceFeePerM2: item.maintenanceFeePerM2,
          nlaRate: item.nlaRate,
          yearQuarter: `${item.year}-${item.quarter}`,
          rentArea: item.rentArea,
          rentFeePerM2: item.rentFeePerM2,
        }
      }),
    [data],
  )

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'yearQuarter_lwhTypeCode',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('prd_check_moment'), t('storage_type')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate content={[props.yearQuarter, codeToName(props.lwhTypeCode)]} textAlign="left" />
        ),
      },
      {
        field: 'rentArea_nlaRate',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        headerTemplate: () => <MultiTemplate isHeader content={[t('rent_area'), t('nla_rate')]} textAlign="left" />,
        template: (props: any) => (
          <MultiTemplate content={[props.rentArea, formatVacancyRate(props.nlaRate)]} textAlign="left" />
        ),
      },
      {
        field: 'rentFeePerM2_maintenanceFeePerM2',
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('rent_fee'), t('maintenance_fee')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              unitFormatter(props.rentFeePerM2, { format: 'won', rounded: isKrw ? 'TEN' : undefined }),
              unitFormatter(props.maintenanceFeePerM2, { format: 'won', rounded: isKrw ? 'TEN' : undefined }),
            ]}
            textAlign="left"
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, areaUnit, currencyUnit],
  )

  const fullColumns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'yearQuarter',
        headerText: t('prd_check_moment'),
        textAlign: 'Left',
        width: 100,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'lwhTypeCode',
        headerText: t('storage_type'),
        textAlign: 'Left',
        width: 100,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: codeNameValueAccessor,
      },
      {
        field: 'rentArea',
        headerText: t('rent_area'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'nlaRate',
        headerText: t('nla_rate'),
        textAlign: 'Left',
        width: 100,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: (field: string, data: any) => formatVacancyRate(data[field]),
      },
      {
        field: 'depositFeePerM2',
        headerText: t('deposit'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: perUnitValueAccessor({ format: 'won', rounded: isKrw ? 'HUNDRED' : undefined }),
      },
      {
        field: 'rentFeePerM2',
        headerText: t('rent_fee'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: perUnitValueAccessor({ format: 'won', rounded: isKrw ? 'TEN' : undefined }),
      },
      {
        field: 'maintenanceFeePerM2',
        headerText: t('maintenance_fee'),
        textAlign: 'Left',
        width: 140,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: perUnitValueAccessor({ format: 'won', rounded: isKrw ? 'TEN' : undefined }),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, areaUnit, currencyUnit],
  )

  const handlePageChange = (page: number) => setPageNum(page)

  return (
    <LoadMoreDataGrid
      columns={isFull ? fullColumns : columns}
      dataSource={dataSource}
      pageModel={data?.page}
      onPageChange={handlePageChange}
      isLoading={isLoading}
    />
  )
}

export default LwhLeasePrdHistoryGrid
