import { FC, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import MultiTemplate from '~/components/DataGrid/MultiTemplate'
import { formatDateYmd } from '~/libs/utils/date'
import { useTranslation } from 'next-i18next'
import DataGrid from '~/components/DataGrid/DataGrid'
import { useAreaFormat, useAreaValueAccessor } from '~/libs/hooks/useFormat'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'

interface PermissionGridProps {
  dataSource: object[] | undefined
  pagerModel?: PagerModel
  onPageChange: (object: { currentPage: number }) => void
}

const PermissionGrid: FC<PermissionGridProps> = ({ dataSource, pagerModel, onPageChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const areaFormatter = useAreaFormat()
  const { areaUnit } = useUserConfiguration()
  const areaValueAccessor = useAreaValueAccessor(areaUnit)

  const columns: ColumnModel[] = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => [
      {
        field: 'archGubun',
        headerText: t('architecture_division'),
        textAlign: 'Left',
        width: 80,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'permissionStep',
        headerText: t('permission_step'),
        textAlign: 'Left',
        width: 90,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'buildingName',
        headerText: t('building_name'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        width: 120,
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'mainPurpose',
        headerText: t('main_purpose'),
        textAlign: 'Left',
        width: 80,
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'platArea',
        headerText: t('plat_area'),
        width: 100,
        valueAccessor: areaValueAccessor({ rounded: 'n2' }),
      },
      {
        field: 'area',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('architecture_area'), t('gra_py')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.archArea ? areaFormatter(props.archArea, { rounded: 'n2' }) : undefined,
              props.totalArea ? areaFormatter(props.totalArea, { rounded: 'n2' }) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'permissionConstructionDateYmd',
        headerText: t('permission_construction_date'),
        width: 100,
        valueAccessor: (field: string, data: any) => formatDateYmd(data[field]),
      },
      {
        field: 'constructionYmd',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => (
          <MultiTemplate
            isHeader
            content={[t('scheduled_construction_date'), t('delay_construction_date')]}
            textAlign="left"
          />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.scheduledConstructionDateYmd ? formatDateYmd(props.scheduledConstructionDateYmd) : undefined,
              props.delayConstructionDateYmd ? formatDateYmd(props.delayConstructionDateYmd) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
      {
        field: 'constructionDateYmd',
        width: 100,
        textAlign: 'Left',
        headerTemplate: () => (
          <MultiTemplate isHeader content={[t('real_construction_date'), t('use_approval_date')]} textAlign="left" />
        ),
        template: (props: any) => (
          <MultiTemplate
            content={[
              props.realConstructionDateYmd ? formatDateYmd(props.realConstructionDateYmd) : undefined,
              props.useAprDateYmd ? formatDateYmd(props.useAprDateYmd) : undefined,
            ]}
            textAlign="left"
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areaUnit],
  )

  return (
    <DataGrid
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      paginationMode="server"
      onPageChange={onPageChange}
    />
  )
}

export default PermissionGrid
