import { FC } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import BubbleWrap from '~/components/Map/PlaceMarker/BubbleWrap'

interface ClientDetailMapMarkerProps {
  buildingName: NullishString
  isMain?: boolean
  isAddress?: boolean
}

const ClientDetailMapMarker: FC<ClientDetailMapMarkerProps> = ({ buildingName, isMain, isAddress }) => {
  return (
    <BubbleWrap width={isAddress ? '100%' : '104px'} color={isMain ? 'purple-700' : 'purple-600'}>
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          display="flex"
          padding="6px"
          justifyContent="center"
          alignItems="center"
          backgroundColor={isMain ? 'purple-600' : 'system-white'}
        >
          {buildingName && (
            <Typography
              w="fit-content"
              variant="caption1"
              whiteSpace="nowrap"
              overflowX="hidden"
              textOverflow="ellipsis"
              fontWeight="semibold"
              color={isMain ? 'system-white' : 'gray-800'}
            >
              {buildingName}
            </Typography>
          )}
        </Box>
      </Box>
    </BubbleWrap>
  )
}

export default ClientDetailMapMarker
