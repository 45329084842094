import React, { FC, PropsWithChildren } from 'react'
import StackingPlanAreaTable from '~/templates/common/view/stacking-plan/StackingPlanAreaTable'
import StackingPlanLesseeTable from '~/templates/common/view/stacking-plan/StackingPlanLesseeTable'
import { SkeletonComponent } from '@syncfusion/ej2-react-notifications'
import { isNotNil } from '@toss/utils'
import NoData from '~/components/NoData'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import StackingPlanLegend from '~/templates/common/view/stacking-plan/StackingPlanLegend'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import useLwhStackingPlan from '~/templates/common/view/stacking-plan/useLwhStackingPlan'
import StackingPlanParams from '~/templates/common/view/stacking-plan/StackingPlanParams'

interface LwhStackingPlanTablesProps extends StackingPlanParams {
  raId: string
}

const LwhStackingPlanTables: FC<PropsWithChildren<LwhStackingPlanTablesProps>> = ({
  raId,
  year,
  quarter,
  dongName,
}) => {
  const { t: common } = useTranslation('common')

  const { lastUpdate } = useLastUpdateDate()

  const {
    stackingPlanResponse: { data, isLoading },
  } = useLwhStackingPlan({ raId, year, quarter, dongName })

  if (isLoading) {
    return <SkeletonComponent width="100%" height="200px" />
  }

  const isPastDate = year && quarter ? lastUpdate.yq !== `${year}-${quarter}` : false

  return (
    <>
      {isNotNil(data) && data.length > 0 && <StackingPlanLegend />}
      <Box display="flex" justifyContent="space-between" gap={6}>
        <StackingPlanAreaTable data={data} onlyFloorColumn={isPastDate} />
        <StackingPlanLesseeTable data={data} />
      </Box>
      {(!isNotNil(data) || data.length === 0) && <NoData noDataMessage={common('message.no_data')} />}
    </>
  )
}

export default LwhStackingPlanTables
