import { useSearchTotalDataV1SearchGet } from '~/libs/apis/data/search/search'
import {
  SearchBuildingOut,
  SearchClientOut,
  SearchLandOut,
  SearchRegionOut,
  SearchSubwayOut,
  SearchTotalDataV1SearchGetParams,
} from '~/libs/apis/data/model'
import { SearchIndexType } from '~/libs/utils/search'
import { isNotNil } from '@toss/utils'

interface TotalOfficeSearch {
  totalSearchList: SearchIndexType[]
  buildings: SearchBuildingOut[]
  regions: SearchRegionOut[]
  lands: SearchLandOut[]
  subways: SearchSubwayOut[]
  clients: SearchClientOut[]
  isLoading: boolean
}

const useGetTotalOfficeSearch = (
  params: SearchTotalDataV1SearchGetParams,
  options?: {
    enabled?: boolean
    keepPreviousData?: boolean
  },
): TotalOfficeSearch => {
  const { data: totalSearchOut, isLoading } = useSearchTotalDataV1SearchGet(params, {
    swr: {
      enabled: options?.enabled,
      keepPreviousData: options?.keepPreviousData,
    },
  })

  // optional 한 타입이 검색결과로 넘어오므로 undefined 값들을 filter 해줍니다.
  const buildings = totalSearchOut?.building.filter((item) => isNotNil(item)) ?? []
  const regions = totalSearchOut?.region.filter((item) => isNotNil(item)) ?? []
  const lands = totalSearchOut?.land.filter((item) => isNotNil(item)) ?? []
  const subways = totalSearchOut?.subway.filter((item) => isNotNil(item)) ?? []
  const clients = totalSearchOut?.client.filter((item) => isNotNil(item)) ?? []

  return {
    totalSearchList: [...subways, ...regions, ...lands, ...clients, ...buildings],
    buildings,
    regions,
    lands,
    subways,
    clients,
    isLoading,
  }
}

export default useGetTotalOfficeSearch
