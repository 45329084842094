import { FC, ReactNode } from 'react'
import BubbleContent from '~/components/Map/PlaceMarker/BubbleContent'
import { formatTextEllipsis } from '~/libs/utils/format'

interface OfficeMarkerProps {
  buildingName: string
  isActive?: boolean
  content?: ReactNode
  leadingIcon?: ReactNode
}

const ColorTemplate = {
  normal: 'purple-600',
  active: 'purple-700',
}
const OfficeMarker: FC<OfficeMarkerProps> = ({ buildingName, isActive, content, leadingIcon }) => {
  return (
    <BubbleContent
      width="104px"
      headerText={formatTextEllipsis(buildingName, 6, 1)}
      color={!isActive ? ColorTemplate.normal : ColorTemplate.active}
      content={content}
      leadingIcon={leadingIcon}
    />
  )
}

export default OfficeMarker
