import React, { FC, Fragment, MouseEventHandler, ReactNode, RefObject, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import DetailSection from '~/templates/place/detail/DetailSection'
import ScrollHighlightWrapperWithTab from '~/templates/ScrollHighlightWrapperWithTab'
import Box from '~/components/Box/Box'
import DetailScrollProvider from '~/templates/DetailScrollProvider'
import {
  DETAIL_GROUP_SECTION_HEIGHT,
  DETAIL_NAVBAR_HEIGHT,
  DETAIL_STICKY_AREA_HEIGHT,
  DETAIL_STICKY_AREA_HEIGHT_WITH_GROUP,
} from '~/libs/constants/place'
import DetailFooterButtons from '~/templates/place/detail/DetailFooterButtons'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface BuildingDetailProps {
  overview: ReactNode
  building: ReactNode
  land: ReactNode
  rental: ReactNode
  lessee: ReactNode
  trade: ReactNode
  registration: ReactNode
  isGroup?: boolean
  isCompletion?: boolean
  isDemolish?: boolean
  hasPnu?: boolean
  isExistsContactNumber?: boolean
  onFullClick: MouseEventHandler
}

const getItemsFromConstructStatusCode = <T extends string | RefObject<HTMLDivElement> | JSX.Element>(
  isDemolish: boolean,
  hasPnu: boolean,
  ...items: T[]
): T[] => {
  // 건물
  const returnItems = [items[0]]
  // 토지: pnu가 존재하지 않는 경우 제외
  if (hasPnu) {
    returnItems.push(items[1])
  }
  // 임대차: 멸실이 아닐 때만 존재
  if (!isDemolish) {
    returnItems.push(items[2])
  }
  // 거래, 등기
  returnItems.push(items[3], items[4])
  return returnItems
}

const BuildingDetail: FC<BuildingDetailProps> = ({
  overview,
  building,
  land,
  rental,
  trade,
  lessee,
  registration,
  isGroup = false,
  isCompletion = true,
  isDemolish = true,
  hasPnu = true,
  isExistsContactNumber = false,
  onFullClick,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const tabItems = getItemsFromConstructStatusCode(
    isDemolish,
    hasPnu,
    t('building'),
    t('estate'),
    t('rental'),
    t('trade'),
    t('registration'),
  )

  const stickyAreaHeight = isGroup ? DETAIL_STICKY_AREA_HEIGHT_WITH_GROUP : DETAIL_STICKY_AREA_HEIGHT
  const scrollMarginTop = stickyAreaHeight + 'px'

  const buildingRef = useRef<HTMLDivElement>(null)
  const landRef = useRef<HTMLDivElement>(null)
  const rentalRef = useRef<HTMLDivElement>(null)
  const tradeRef = useRef<HTMLDivElement>(null)
  const registrationRef = useRef<HTMLDivElement>(null)
  const sections = getItemsFromConstructStatusCode(
    isDemolish,
    hasPnu,
    buildingRef,
    landRef,
    rentalRef,
    tradeRef,
    registrationRef,
  )

  const buildingSection = (
    <DetailSection key="building" ref={buildingRef} scrollMarginTop={scrollMarginTop}>
      {building}
    </DetailSection>
  )
  const landSection = (
    <DetailSection key="land" ref={landRef} landingContent="land" scrollMarginTop={scrollMarginTop}>
      {land}
    </DetailSection>
  )
  const rentalSection = (
    <Fragment key="rental">
      <DetailSection ref={rentalRef} landingContent="rental" scrollMarginTop={scrollMarginTop}>
        {rental}
      </DetailSection>
      {isCompletion && (
        <DetailSection landingContent="stackingPlan" scrollMarginTop={scrollMarginTop}>
          {lessee}
        </DetailSection>
      )}
    </Fragment>
  )
  const tradeSection = (
    <DetailSection key="trade" ref={tradeRef} landingContent="trade" scrollMarginTop={scrollMarginTop}>
      {trade}
    </DetailSection>
  )
  const registrationSection = (
    <DetailSection
      key="registration"
      ref={registrationRef}
      landingContent="registration"
      scrollMarginTop={scrollMarginTop}
    >
      {registration}
    </DetailSection>
  )

  const sectionElements = getItemsFromConstructStatusCode(
    isDemolish,
    hasPnu,
    buildingSection,
    landSection,
    rentalSection,
    tradeSection,
    registrationSection,
  )

  const contents = (
    <Box display="flex" flexDirection="column" backgroundColor="gray-100" gap="16px">
      {sectionElements}
    </Box>
  )

  const tabTopHeight = isGroup ? DETAIL_NAVBAR_HEIGHT + DETAIL_GROUP_SECTION_HEIGHT : DETAIL_NAVBAR_HEIGHT

  return (
    <DetailScrollProvider>
      <ScrollHighlightWrapperWithTab
        stickyAreaHeight={stickyAreaHeight}
        sections={sections}
        stickyArea={overview}
        tabItems={tabItems}
        tabTop={tabTopHeight}
        sectionContent={contents}
        testId="building-detail"
        showFooter
        autoFocus={isFull}
      />
      <DetailFooterButtons isExistsContactNumber={isExistsContactNumber} onFullClick={onFullClick} />
    </DetailScrollProvider>
  )
}

export default BuildingDetail
