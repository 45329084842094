import React, { FC, MouseEventHandler } from 'react'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Box from '../../../components/Box/Box'
import Button from '~/components/Button/Button'
import IconTalk from '~/assets/images/icons/system/system-talk.svg'
import { useTranslation } from 'next-i18next'
import styled, { useTheme } from '@xstyled/styled-components'
import Tooltip from '~/components/Tooltip/Tooltip'
import useInquiryControl from '~/libs/hooks/useInquiryControl'
import { DETAIL_FOOTER_BUTTONS_Z_INDEX } from '~/libs/constants/place'

interface DetailFooterButtonsProps {
  isExistsContactNumber?: boolean | null
  onFullClick: MouseEventHandler
}
const DetailFooterButtons: FC<DetailFooterButtonsProps> = ({ isExistsContactNumber = false, onFullClick }) => {
  const theme = useTheme()
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: inquiryTerm } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const { detailType, detailContent, uid } = useDetailInfo()
  const { hide } = useFullDetailDialog()
  const { openDialog: openInquiryDialog } = useInquiryControl()

  const inquiryButtonWidth = detailType === 'panel' ? '120px' : '280px'

  const handleInquiryButtonClick = () => {
    openInquiryDialog({ type: detailContent, targetId: uid })
  }

  return (
    <Box
      key="fullButton"
      width="100%"
      position="absolute"
      bottom={0}
      p={5}
      backgroundColor="system-white"
      zIndex={DETAIL_FOOTER_BUTTONS_Z_INDEX}
    >
      <Box display="flex" gap="10px">
        {isExistsContactNumber ? (
          <Box flex={1}>
            <Tooltip
              content={inquiryTerm('fast_inquiry_description')}
              color="white"
              position="TopCenter"
              opensOn="Hover"
            >
              <Button
                size="lg"
                width={inquiryButtonWidth}
                content={inquiryTerm('fast_inquiry')}
                color="darkGray"
                onClick={handleInquiryButtonClick}
              />
            </Tooltip>
          </Box>
        ) : (
          <LightPrimaryButton
            width={inquiryButtonWidth}
            size="lg"
            content={inquiryTerm('on_inquiry')}
            icon={<IconTalk color={theme.colors['purple-700']} />}
            onClick={handleInquiryButtonClick}
          />
        )}
        {detailType === 'panel' ? (
          <Button width="100%" size="lg" content={t('dtl_view')} color="black" onClick={onFullClick} />
        ) : (
          <Button width="100%" size="lg" content={t('close')} color="black" onClick={hide} />
        )}
      </Box>
    </Box>
  )
}

/**
 * 일반문의 버튼은 스타일가이드에 없음.
 */
const LightPrimaryButton = styled(Button)`
  &.e-btn,
  &.e-btn.e-active,
  &.e-btn.e-active:not(:disabled) {
    background-color: var(--color-purple-100) !important;
    border-color: var(--color-purple-100) !important;
    color: var(--color-purple-700) !important;
  }
`

export default DetailFooterButtons
