/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  BuildingsLwhLesseeChangeOut
} from '../model/buildingsLwhLesseeChangeOut'
import type {
  GetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetParams
} from '../model/getLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetParams'
import type {
  GetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetParams
} from '../model/getLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetParams'
import type {
  GetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetParams
} from '../model/getLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetParams'
import type {
  GetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetParams
} from '../model/getLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetParams'
import type {
  GetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetParams
} from '../model/getLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetParams'
import type {
  GetLwhSupplyDataV1BuildingsLwhSupplyGetParams
} from '../model/getLwhSupplyDataV1BuildingsLwhSupplyGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  LwhDetailListDataV1BuildingsLwhGetParams
} from '../model/lwhDetailListDataV1BuildingsLwhGetParams'
import type {
  LwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetParams
} from '../model/lwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetParams'
import type {
  LwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetParams
} from '../model/lwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetParams'
import type {
  LwhLeaseStatusOut
} from '../model/lwhLeaseStatusOut'
import type {
  LwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetParams
} from '../model/lwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetParams'
import type {
  LwhLeaseTrendOut
} from '../model/lwhLeaseTrendOut'
import type {
  LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams
} from '../model/lwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams'
import type {
  LwhLesseeDataV1BuildingsLwhLesseeGetParams
} from '../model/lwhLesseeDataV1BuildingsLwhLesseeGetParams'
import type {
  LwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetParams
} from '../model/lwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetParams'
import type {
  LwhLesseeStatusOut
} from '../model/lwhLesseeStatusOut'
import type {
  LwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetParams
} from '../model/lwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetParams'
import type {
  LwhNearLeasePagingOut
} from '../model/lwhNearLeasePagingOut'
import type {
  LwhNearScheduledSupplyPagingOut
} from '../model/lwhNearScheduledSupplyPagingOut'
import type {
  LwhNearTradePagingOut
} from '../model/lwhNearTradePagingOut'
import type {
  LwhOut
} from '../model/lwhOut'
import type {
  LwhPrdMovementPagingOut
} from '../model/lwhPrdMovementPagingOut'
import type {
  LwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetParams
} from '../model/lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetParams'
import type {
  LwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetParams
} from '../model/lwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetParams'
import type {
  LwhStackingPlanDongOut
} from '../model/lwhStackingPlanDongOut'
import type {
  LwhStackingPlanOut
} from '../model/lwhStackingPlanOut'
import type {
  LwhSupplyPagingOut
} from '../model/lwhSupplyPagingOut'
import type {
  LwhTradeDataV1BuildingsLwhTradeGetParams
} from '../model/lwhTradeDataV1BuildingsLwhTradeGetParams'
import type {
  RaIdBase
} from '../model/raIdBase'
import type {
  SchemasBuildingsLwhLeaseMarketTrendOut
} from '../model/schemasBuildingsLwhLeaseMarketTrendOut'
import type {
  SchemasBuildingsLwhLesseePagingOut
} from '../model/schemasBuildingsLwhLesseePagingOut'
import type {
  SchemasBuildingsLwhTradePagingOut
} from '../model/schemasBuildingsLwhTradePagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 물류창고 기본정보 리스트(사용 테이블: ra_v2.mv_buildings_lwh_detail)
 */
export const lwhDetailListDataV1BuildingsLwhGet = (
    params: LwhDetailListDataV1BuildingsLwhGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhOut[]>(
      {url: `/data/v1/buildings/lwh`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhDetailListDataV1BuildingsLwhGetKey = (params: LwhDetailListDataV1BuildingsLwhGetParams,) => [`/data/v1/buildings/lwh`, ...(params ? [params]: [])] as const;

    
export type LwhDetailListDataV1BuildingsLwhGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhDetailListDataV1BuildingsLwhGet>>>
export type LwhDetailListDataV1BuildingsLwhGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 기본정보 리스트(사용 테이블: ra_v2.mv_buildings_lwh_detail)
 */
export const useLwhDetailListDataV1BuildingsLwhGet = <TError = HTTPValidationError>(
 params: LwhDetailListDataV1BuildingsLwhGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhDetailListDataV1BuildingsLwhGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhDetailListDataV1BuildingsLwhGetKey(params) : null);
  const swrFn = () => lwhDetailListDataV1BuildingsLwhGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const getLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet = (
    params: GetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhLeaseTrendOut[]>(
      {url: `/data/v1/buildings/lwh/lease/trend`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetKey = (params: GetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetParams,) => [`/data/v1/buildings/lwh/lease/trend`, ...(params ? [params]: [])] as const;

    
export type GetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet>>>
export type GetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const useGetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet = <TError = HTTPValidationError>(
 params: GetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGetKey(params) : null);
  const swrFn = () => getLwhLeaseTrendDataV1BuildingsLwhLeaseTrendGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 건물/권역 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const lwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGet = (
    params: LwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<unknown>(
      {url: `/data/v1/buildings/lwh/lease/trend/excel`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetKey = (params: LwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetParams,) => [`/data/v1/buildings/lwh/lease/trend/excel`, ...(params ? [params]: [])] as const;

    
export type LwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGet>>>
export type LwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetQueryError = HTTPValidationError

/**
 * @summary 오피스 건물/권역 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const useLwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGet = <TError = HTTPValidationError>(
 params: LwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGetKey(params) : null);
  const swrFn = () => lwhLeaseTrendExcelDataV1BuildingsLwhLeaseTrendExcelGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 마켓 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const lwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet = (
    params: LwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasBuildingsLwhLeaseMarketTrendOut[]>(
      {url: `/data/v1/buildings/lwh/lease/market/trend`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetKey = (params: LwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetParams,) => [`/data/v1/buildings/lwh/lease/market/trend`, ...(params ? [params]: [])] as const;

    
export type LwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet>>>
export type LwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 마켓 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const useLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet = <TError = HTTPValidationError>(
 params: LwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGetKey(params) : null);
  const swrFn = () => lwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 임대 시세(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const lwhLeaseStatusDataV1BuildingsLwhLeaseStatusGet = (
    params: LwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhLeaseStatusOut[]>(
      {url: `/data/v1/buildings/lwh/lease/status`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetKey = (params: LwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetParams,) => [`/data/v1/buildings/lwh/lease/status`, ...(params ? [params]: [])] as const;

    
export type LwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhLeaseStatusDataV1BuildingsLwhLeaseStatusGet>>>
export type LwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 임대 시세(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const useLwhLeaseStatusDataV1BuildingsLwhLeaseStatusGet = <TError = HTTPValidationError>(
 params: LwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhLeaseStatusDataV1BuildingsLwhLeaseStatusGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhLeaseStatusDataV1BuildingsLwhLeaseStatusGetKey(params) : null);
  const swrFn = () => lwhLeaseStatusDataV1BuildingsLwhLeaseStatusGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 공급사례(사용 테이블: ra_v2.mv_buildings_lwh_trade_trend)
 */
export const getLwhSupplyDataV1BuildingsLwhSupplyGet = (
    params: GetLwhSupplyDataV1BuildingsLwhSupplyGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhSupplyPagingOut>(
      {url: `/data/v1/buildings/lwh/supply`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLwhSupplyDataV1BuildingsLwhSupplyGetKey = (params: GetLwhSupplyDataV1BuildingsLwhSupplyGetParams,) => [`/data/v1/buildings/lwh/supply`, ...(params ? [params]: [])] as const;

    
export type GetLwhSupplyDataV1BuildingsLwhSupplyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLwhSupplyDataV1BuildingsLwhSupplyGet>>>
export type GetLwhSupplyDataV1BuildingsLwhSupplyGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 공급사례(사용 테이블: ra_v2.mv_buildings_lwh_trade_trend)
 */
export const useGetLwhSupplyDataV1BuildingsLwhSupplyGet = <TError = HTTPValidationError>(
 params: GetLwhSupplyDataV1BuildingsLwhSupplyGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLwhSupplyDataV1BuildingsLwhSupplyGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLwhSupplyDataV1BuildingsLwhSupplyGetKey(params) : null);
  const swrFn = () => getLwhSupplyDataV1BuildingsLwhSupplyGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 거래내역(사용 테이블: ra_v2.mv_buildings_lwh_trade_trend)
 */
export const lwhTradeDataV1BuildingsLwhTradeGet = (
    params?: LwhTradeDataV1BuildingsLwhTradeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasBuildingsLwhTradePagingOut>(
      {url: `/data/v1/buildings/lwh/trade`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhTradeDataV1BuildingsLwhTradeGetKey = (params?: LwhTradeDataV1BuildingsLwhTradeGetParams,) => [`/data/v1/buildings/lwh/trade`, ...(params ? [params]: [])] as const;

    
export type LwhTradeDataV1BuildingsLwhTradeGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhTradeDataV1BuildingsLwhTradeGet>>>
export type LwhTradeDataV1BuildingsLwhTradeGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 거래내역(사용 테이블: ra_v2.mv_buildings_lwh_trade_trend)
 */
export const useLwhTradeDataV1BuildingsLwhTradeGet = <TError = HTTPValidationError>(
 params?: LwhTradeDataV1BuildingsLwhTradeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhTradeDataV1BuildingsLwhTradeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhTradeDataV1BuildingsLwhTradeGetKey(params) : null);
  const swrFn = () => lwhTradeDataV1BuildingsLwhTradeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 임차인(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const lwhLesseeDataV1BuildingsLwhLesseeGet = (
    params: LwhLesseeDataV1BuildingsLwhLesseeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasBuildingsLwhLesseePagingOut>(
      {url: `/data/v1/buildings/lwh/lessee`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhLesseeDataV1BuildingsLwhLesseeGetKey = (params: LwhLesseeDataV1BuildingsLwhLesseeGetParams,) => [`/data/v1/buildings/lwh/lessee`, ...(params ? [params]: [])] as const;

    
export type LwhLesseeDataV1BuildingsLwhLesseeGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhLesseeDataV1BuildingsLwhLesseeGet>>>
export type LwhLesseeDataV1BuildingsLwhLesseeGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 임차인(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const useLwhLesseeDataV1BuildingsLwhLesseeGet = <TError = HTTPValidationError>(
 params: LwhLesseeDataV1BuildingsLwhLesseeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhLesseeDataV1BuildingsLwhLesseeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhLesseeDataV1BuildingsLwhLesseeGetKey(params) : null);
  const swrFn = () => lwhLesseeDataV1BuildingsLwhLesseeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 기본정보(사용 테이블: ra_v2.mv_buildings_lwh_detail)
 */
export const lwhDetailDataV1BuildingsLwhRaIdGet = (
    raId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhOut>(
      {url: `/data/v1/buildings/lwh/${raId}`, method: 'GET'
    },
      options);
    }
  

export const getLwhDetailDataV1BuildingsLwhRaIdGetKey = (raId: string,) => [`/data/v1/buildings/lwh/${raId}`] as const;

    
export type LwhDetailDataV1BuildingsLwhRaIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhDetailDataV1BuildingsLwhRaIdGet>>>
export type LwhDetailDataV1BuildingsLwhRaIdGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 기본정보(사용 테이블: ra_v2.mv_buildings_lwh_detail)
 */
export const useLwhDetailDataV1BuildingsLwhRaIdGet = <TError = HTTPValidationError>(
 raId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhDetailDataV1BuildingsLwhRaIdGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhDetailDataV1BuildingsLwhRaIdGetKey(raId) : null);
  const swrFn = () => lwhDetailDataV1BuildingsLwhRaIdGet(raId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 주변 거래(사용 테이블: ra_v2.mv_buildings_lwh_detail)
 */
export const getLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGet = (
    raId: string,
    params?: GetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhNearTradePagingOut>(
      {url: `/data/v1/buildings/lwh/${raId}/nearby/trade`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetKey = (raId: string,
    params?: GetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetParams,) => [`/data/v1/buildings/lwh/${raId}/nearby/trade`, ...(params ? [params]: [])] as const;

    
export type GetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGet>>>
export type GetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 주변 거래(사용 테이블: ra_v2.mv_buildings_lwh_detail)
 */
export const useGetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGet = <TError = HTTPValidationError>(
 raId: string,
    params?: GetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGetKey(raId,params) : null);
  const swrFn = () => getLwhNearbyTradeDataV1BuildingsLwhRaIdNearbyTradeGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 주변분석 공급예정 리스트(사용 테이블: ra_v2.mv_buildings_lwh_supply)
 */
export const getLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGet = (
    raId: string,
    params?: GetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhNearScheduledSupplyPagingOut>(
      {url: `/data/v1/buildings/lwh/${raId}/nearby/scheduled-supply`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetKey = (raId: string,
    params?: GetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetParams,) => [`/data/v1/buildings/lwh/${raId}/nearby/scheduled-supply`, ...(params ? [params]: [])] as const;

    
export type GetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGet>>>
export type GetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 주변분석 공급예정 리스트(사용 테이블: ra_v2.mv_buildings_lwh_supply)
 */
export const useGetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGet = <TError = HTTPValidationError>(
 raId: string,
    params?: GetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGetKey(raId,params) : null);
  const swrFn = () => getLwhNearbyScheduledSupplyDataV1BuildingsLwhRaIdNearbyScheduledSupplyGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 주변분석 임대 리스트(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const lwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGet = (
    raId: string,
    params: LwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhNearLeasePagingOut>(
      {url: `/data/v1/buildings/lwh/${raId}/nearby/lease`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetKey = (raId: string,
    params: LwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetParams,) => [`/data/v1/buildings/lwh/${raId}/nearby/lease`, ...(params ? [params]: [])] as const;

    
export type LwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGet>>>
export type LwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 주변분석 임대 리스트(사용 테이블: ra_v2.mv_buildings_lwh_lease_trend)
 */
export const useLwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGet = <TError = HTTPValidationError>(
 raId: string,
    params: LwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGetKey(raId,params) : null);
  const swrFn = () => lwhNearLeaseDataV1BuildingsLwhRaIdNearbyLeaseGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 유사건물(사용 테이블: ra_v2.dw_f_similar_building)
 */
export const lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet = (
    raId: string,
    params?: LwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RaIdBase[]>(
      {url: `/data/v1/buildings/lwh/${raId}/similar`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetKey = (raId: string,
    params?: LwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetParams,) => [`/data/v1/buildings/lwh/${raId}/similar`, ...(params ? [params]: [])] as const;

    
export type LwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet>>>
export type LwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 유사건물(사용 테이블: ra_v2.dw_f_similar_building)
 */
export const useLwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet = <TError = HTTPValidationError>(
 raId: string,
    params?: LwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGetKey(raId,params) : null);
  const swrFn = () => lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 임차인 현황(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const lwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGet = (
    raId: string,
    params: LwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhLesseeStatusOut[]>(
      {url: `/data/v1/buildings/lwh/${raId}/lessee/status`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetKey = (raId: string,
    params: LwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetParams,) => [`/data/v1/buildings/lwh/${raId}/lessee/status`, ...(params ? [params]: [])] as const;

    
export type LwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGet>>>
export type LwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 임차인 현황(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const useLwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGet = <TError = HTTPValidationError>(
 raId: string,
    params: LwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGetKey(raId,params) : null);
  const swrFn = () => lwhLesseeStatusDataV1BuildingsLwhRaIdLesseeStatusGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 임차인 변동(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const lwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet = (
    raId: string,
    params: LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsLwhLesseeChangeOut>(
      {url: `/data/v1/buildings/lwh/${raId}/lessee/change`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetKey = (raId: string,
    params: LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams,) => [`/data/v1/buildings/lwh/${raId}/lessee/change`, ...(params ? [params]: [])] as const;

    
export type LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet>>>
export type LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 임차인 변동(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const useLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet = <TError = HTTPValidationError>(
 raId: string,
    params: LwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGetKey(raId,params) : null);
  const swrFn = () => lwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 스태킹플랜(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const lwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGet = (
    raId: string,
    params: LwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhStackingPlanOut[]>(
      {url: `/data/v1/buildings/lwh/${raId}/stacking-plan`, method: 'GET',
        params
    },
      options);
    }
  

export const getLwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetKey = (raId: string,
    params: LwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetParams,) => [`/data/v1/buildings/lwh/${raId}/stacking-plan`, ...(params ? [params]: [])] as const;

    
export type LwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetQueryResult = NonNullable<Awaited<ReturnType<typeof lwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGet>>>
export type LwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 스태킹플랜(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const useLwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGet = <TError = HTTPValidationError>(
 raId: string,
    params: LwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof lwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getLwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGetKey(raId,params) : null);
  const swrFn = () => lwhStackingPlanDataV1BuildingsLwhRaIdStackingPlanGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 스태킹플랜 동 리스트 조회(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const getLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGet = (
    raId: string,
    params: GetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhStackingPlanDongOut[]>(
      {url: `/data/v1/buildings/lwh/${raId}/stacking-plan/dong`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetKey = (raId: string,
    params: GetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetParams,) => [`/data/v1/buildings/lwh/${raId}/stacking-plan/dong`, ...(params ? [params]: [])] as const;

    
export type GetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGet>>>
export type GetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 스태킹플랜 동 리스트 조회(사용 테이블: ra_v2.mv_building_lwh_tenant_from_rtb)
 */
export const useGetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGet = <TError = HTTPValidationError>(
 raId: string,
    params: GetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGetKey(raId,params) : null);
  const swrFn = () => getLwhStackingPlanDongDataV1BuildingsLwhRaIdStackingPlanDongGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 물류창고 임대매물 (사용 테이블: ra_v2.mv_buildings_prd_movement)
 */
export const getLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet = (
    raId: string,
    params?: GetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LwhPrdMovementPagingOut>(
      {url: `/data/v1/buildings/lwh/${raId}/prd-movement`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetKey = (raId: string,
    params?: GetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetParams,) => [`/data/v1/buildings/lwh/${raId}/prd-movement`, ...(params ? [params]: [])] as const;

    
export type GetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet>>>
export type GetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetQueryError = HTTPValidationError

/**
 * @summary 물류창고 임대매물 (사용 테이블: ra_v2.mv_buildings_prd_movement)
 */
export const useGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet = <TError = HTTPValidationError>(
 raId: string,
    params?: GetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGetKey(raId,params) : null);
  const swrFn = () => getLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

