import { FC, ReactNode } from 'react'
import { Box } from '~/components'
import InfoIcon from '~/assets/images/icons/info.svg'
import NoticeIcon from '~/assets/images/icons/notice.svg'
import GraphicNormal from '~/assets/images/graphic-normal.svg?url'
import GraphicNotice from '~/assets/images/graphic-notice.svg?url'
import Typography from '~/components/Typography/Typography'

interface NoticeBoxProps {
  type: 'normal' | 'notice'
  title?: string
  content: ReactNode | string
}

const NoticeBox: FC<NoticeBoxProps> = ({ type, title, content }) => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      gap="10px"
      borderRadius="8px"
      padding="14px 12px"
      backgroundColor={type === 'normal' ? 'var(--color-blue-100)' : 'var(--color-green-100)'}
      style={
        title
          ? {
              backgroundImage: `url(${type === 'normal' ? GraphicNormal : GraphicNotice})`,
              backgroundRepeat: 'repeat-y',
              objectFit: 'contain',
              backgroundPosition: 'top 0 right 0',
            }
          : undefined
      }
    >
      <Box
        display="flex"
        flexDirection={title ? 'column' : 'row'}
        alignItems={title ? undefined : 'center'}
        gap={title ? '10px' : undefined}
      >
        <Box display="flex" alignItems="center" gap={title ? '6px' : '10px'}>
          {type === 'normal' ? (
            <InfoIcon width="14px" height="14px" color="var(--color-blue-600)" />
          ) : (
            <NoticeIcon width="14px" height="14px" />
          )}
          <Typography fontWeight="bold" color="var(--color-blue-600)">
            {title}
          </Typography>
        </Box>
        <Box zIndex="1">{content}</Box>
      </Box>
    </Box>
  )
}

export default NoticeBox
