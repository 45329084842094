import OfficeMarker from '~/components/Map/PlaceMarker/OfficeMarker'
import WarehouseMarker from '~/components/Map/PlaceMarker/LwhMarker'
import AddressMarker from '~/components/Map/PlaceMarker/AddressMarker'
import { BuildingType } from '~/templates/place/detail/overview/BuildingMapImageArea'
import { FC } from 'react'

interface BuildingMapMarkerProps {
  content: string
  type: BuildingType
}

const BuildingMapMarker: FC<BuildingMapMarkerProps> = ({ content, type }) => {
  switch (type) {
    case 'office':
      return <OfficeMarker isActive buildingName={content} />
    case 'lwh':
      return <WarehouseMarker isActive buildingName={content} />
    case 'land':
      return <AddressMarker isActive address={content} />
    default:
      return null
  }
}

export default BuildingMapMarker
