import BuildingImage from '~/components/Image/BuildingImage'
import Box from '~/components/Box/Box'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import ImageModal from '~/templates/place/detail/overview/ImageModal'
import { GeomJson, OfficeOutImageUrl } from '~/libs/apis/data/model'
import { MapMarkerWrapper, Offset, Position } from '@mint-ui/map'
import { LAND_MARKER_HEIGHT, PLACE_MARKER_ANCHOR_X } from '~/libs/constants/place'
import BuildingMapMarker from '~/templates/place/detail/overview/BuildingMapMarker'
import BuildingBoundaryMarker from '~/components/Map/BuildingBoundaryMarker'
import ContentMintMap from '~/components/Map/ContentMintMap'
import { geomJsonToMultiPositions } from '~/libs/utils/map'
import { MAP_LAYER_Z_INDEX, NMapZoomLevel } from '~/libs/constants/common'

export type BuildingType = 'office' | 'lwh' | 'land'

interface BuildingMapImageAreaProps {
  imageUrl?: OfficeOutImageUrl
  position: Position
  markerContent: string
  markerType: BuildingType
  boundary: GeomJson | null
}

const BuildingMapImageArea = ({
  imageUrl,
  position,
  markerContent,
  markerType,
  boundary,
}: BuildingMapImageAreaProps) => {
  const [showImageModal, setShowImageModal] = useState(false)
  const toggleShowImageModal = () => setShowImageModal((prev) => !prev)
  const positions = boundary ? geomJsonToMultiPositions(boundary).flat() : undefined

  return (
    <Box position="relative" display="flex">
      <Box flexGrow={1} height={300}>
        <ContentMintMap
          base={{
            center: position,
            zoomLevel: NMapZoomLevel['20m'],
          }}
          focusBoundary={positions}
        >
          <BuildingBoundaryMarker boundary={boundary} />
          <MapMarkerWrapper
            position={position}
            anchor={new Offset(PLACE_MARKER_ANCHOR_X, LAND_MARKER_HEIGHT)}
            zIndex={MAP_LAYER_Z_INDEX.OFFICE_DETAIL_MARKER}
          >
            <BuildingMapMarker content={markerContent} type={markerType} />
          </MapMarkerWrapper>
        </ContentMintMap>
      </Box>
      {imageUrl && (
        <Box cursor="pointer" onClick={toggleShowImageModal}>
          <BuildingImage url={imageUrl} width={560} height={300} />
        </Box>
      )}
      {showImageModal &&
        imageUrl &&
        createPortal(<ImageModal imageUrl={imageUrl} onClose={toggleShowImageModal} />, document.body)}
    </Box>
  )
}

export default BuildingMapImageArea
