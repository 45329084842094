import { Separated, useIsMounted } from '@toss/react'
import { isNonEmptyArray, isNotNil } from '@toss/utils'
import { useTranslation } from 'next-i18next'
import { FC, useDeferredValue, useEffect, useRef, useState } from 'react'
import IconPdf from '~/assets/images/icons/pdf.svg'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import Typography from '~/components/Typography/Typography'
import { useGetRegistrationsDataV1RegistrationsGet } from '~/libs/apis/data/registrations/registrations'
import useR3CommonCode from '~/libs/hooks/useR3CommonCode'
import { getAccessToken } from '~/libs/utils/customInstance'
import { formatISODateYmd } from '~/libs/utils/date'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { usePdfDialog } from '~/templates/PdfDialogContextProvider'
import ChangeResultGrid from '~/templates/place/detail/registration/ChangeResultGrid'
import MessageComponent from '~/templates/place/detail/registration/MessageComponent'
import OpenNewRegistration from '~/templates/place/detail/registration/OpenNewRegistration'
import RegistrationCount from '~/templates/place/detail/registration/RegistrationCount'
import RegistrationGridComponent from '~/templates/place/detail/registration/RegistrationGridComponent'
import SelectRegistrationDetailDialog from '~/templates/place/detail/registration/SelectRegistrationDetailDialog'
import RaDataError from '~/libs/errors/RaDataError'
import { ERROR_STATUS_DATAHUB } from '~/libs/constants/common'

interface RegistrationInfoProps {
  pnu: string
  address: string | null
}

const RegistrationInfo: FC<RegistrationInfoProps> = ({ pnu, address }) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const [visible, setVisible] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { codeToName } = useR3CommonCode('PROPERTY')
  const { detailType, selectedRegistrationId, landing } = useDetailInfo()
  const isFull = detailType === 'full'
  const { open } = usePdfDialog()
  const ref = useRef<HTMLDivElement>(null)

  const { data: registrations, error } = useGetRegistrationsDataV1RegistrationsGet(
    { pnus: pnu },
    {
      swr: { enabled: Boolean(pnu), shouldRetryOnError: false },
    },
  )

  const data = registrations?.data

  const registrationCount = registrations?.page?.totalSize ?? 0
  const hasRegistration = registrationCount !== 0

  const toggleVisible = (value: boolean) => () => setVisible(value)

  const handleSelectIndexChange = (newIndex: number) => setSelectedIndex(newIndex)
  const isDowntime = isNotNil(error) && error instanceof RaDataError && error.status === ERROR_STATUS_DATAHUB
  const isMounted = useDeferredValue(useIsMounted())

  useEffect(() => {
    setSelectedIndex(0)
    if (selectedRegistrationId && data && isMounted) {
      const selectedRegistrationIndex = data.findIndex((item) => item.registrationId === selectedRegistrationId)
      setSelectedIndex(selectedRegistrationIndex)
      if (!landing) {
        ref.current?.scrollIntoView(true)
      }
    }
  }, [selectedRegistrationId, data, isMounted, landing])

  const handleRegistrationPdfDownload = () => {
    if (data && isNonEmptyArray(data)) {
      open(data[selectedIndex])
    }
  }

  if (isDowntime) {
    return (
      <>
        <RegistrationCount />
        <MessageComponent message={t('data_hub_downtime')} />
      </>
    )
  }

  if (!data || !hasRegistration) {
    return (
      <>
        <RegistrationCount count={registrationCount} />
        <MessageComponent message={t('no_registration')} />
        <OpenNewRegistration address={address} />
      </>
    )
  }

  const registration = data[selectedIndex]
  const registrationId = registration?.registrationId.toString()
  const realEstateNumber = registration?.registrationRealEstateNumber

  const handleCheckNewRegistration = () => {
    window.open(
      `${process.env.DATAHUB_URL}/register-request?filter=realEstateNumber&ra-token=${getAccessToken()}&realEstateNumber=${realEstateNumber}`,
      '_blank',
      'noopener',
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1} mb={3} ref={ref}>
        <RegistrationCount count={registrationCount} />
        <Typography variant="caption1" color="gray-700">
          {t('registration_notice_message')}
        </Typography>
      </Box>
      {data.length > 1 && (
        <Box
          display="flex"
          height="60px"
          backgroundColor="gray-100"
          borderRadius="10px"
          p="12px 16px"
          mb={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body" fontWeight="semibold" color="gray-800">
            {t('view_message')}
          </Typography>
          <Button size="md" width={isFull ? '160px' : undefined} content={t('view')} onClick={toggleVisible(true)} />
        </Box>
      )}
      <Box display="flex" flexDirection="column" borderRadius="10px" boxShadow="black-05" gap="30px" p={4}>
        <Box>
          <Typography fontWeight="semibold" color="purple-700">
            {t('selected_registration')}
          </Typography>
          <Typography variant="subtitle" color="gray-800" mt="14px" mb="10px">
            {registration?.address}
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Separated with={<Box backgroundColor="gray-500" width="1px" height="14px" />}>
              <Typography color="gray-800">
                {t('registration_open_date', { date: formatISODateYmd(registration?.registrationCompletedAt) })}
              </Typography>
              <Typography color="gray-600">
                {registration?.registrationPropertyTypeCode
                  ? codeToName(registration?.registrationPropertyTypeCode)
                  : registration?.registrationPropertyTypeCode}
              </Typography>
              <Typography color="gray-600">{registration?.registrationRealEstateNumber}</Typography>
            </Separated>
          </Box>
        </Box>
        <Box display="flex" gap="10px">
          <Button
            color="gray"
            width="100%"
            size="lg"
            content={t('open_new_registration')}
            onClick={handleCheckNewRegistration}
          />
          <Button
            color="analysisPurple"
            icon={<IconPdf />}
            width="100%"
            size="lg"
            content={t('view_pdf')}
            onClick={handleRegistrationPdfDownload}
          />
        </Box>
        <Box backgroundColor="gray-300" height="1px" width="100%" />
        <RegistrationGridComponent registrationId={registrationId} />
        {registration?.isRaManaged && <ChangeResultGrid pnu={pnu} />}
      </Box>
      {visible && (
        <SelectRegistrationDetailDialog
          list={data}
          address={address}
          selectedIndex={selectedIndex}
          visible={visible}
          onConfirm={handleSelectIndexChange}
          onClose={toggleVisible(false)}
        />
      )}
    </>
  )
}

export default RegistrationInfo
