import Typography from '~/components/Typography/Typography'
import HoGrid from '~/templates/place/detail/common-data/HoGrid'
import FloorGrid from '~/templates/place/detail/common-data/FloorGrid'
import { FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  useLedgerFloorDataV1GovLedgerFloorGet,
  useLedgerPossessionDataV1GovLedgerPossessionGet,
} from '~/libs/apis/data/gov/gov'
import {
  LedgerFloorDataV1GovLedgerFloorGetParams,
  LedgerPossessionDataV1GovLedgerPossessionGetParams,
} from '~/libs/apis/data/model'
import { isNotNil } from '@toss/utils'

interface FloorAndHoInfoProps {
  mgmBldrgstPk: string
}

const PAGE_SIZE = 10
const PAGE_COUNT = 5

const FloorAndHoInfo: FC<FloorAndHoInfoProps> = ({ mgmBldrgstPk }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const [hoParams, setHoParams] = useState<LedgerPossessionDataV1GovLedgerPossessionGetParams>({
    mgmBldrgstPk,
    pageNum: 1,
    pageSize: PAGE_SIZE,
  })
  const [floorParams, setFloorParams] = useState<LedgerFloorDataV1GovLedgerFloorGetParams>({
    mgmBldrgstPk,
    pageNum: 1,
    pageSize: PAGE_SIZE,
  })

  const { data: ho } = useLedgerPossessionDataV1GovLedgerPossessionGet(hoParams, {
    swr: {
      enabled: isNotNil(hoParams.mgmBldrgstPk),
      keepPreviousData: true,
    },
  })
  const { data: floor } = useLedgerFloorDataV1GovLedgerFloorGet(floorParams, {
    swr: {
      enabled: isNotNil(floorParams.mgmBldrgstPk),
      keepPreviousData: true,
    },
  })

  const hoPage = ho?.page
  const hoPagerModel = hoPage
    ? {
        pageSize: hoPage.pageSize,
        pageCount: PAGE_COUNT,
        currentPage: hoPage.page,
        totalRecordsCount: hoPage.totalSize,
      }
    : undefined

  const floorPage = floor?.page
  const floorPagerModel = floorPage
    ? {
        pageSize: floorPage.pageSize,
        pageCount: PAGE_COUNT,
        currentPage: floorPage.page,
        totalRecordsCount: floorPage.totalSize,
      }
    : undefined

  const handleHoPageChange = (object: { currentPage: number }) =>
    setHoParams((prev) => ({ ...prev, pageNum: object.currentPage }))
  const handleFloorPageChange = (object: { currentPage: number }) =>
    setFloorParams((prev) => ({ ...prev, pageNum: object.currentPage }))

  return (
    <>
      <Typography fontWeight="semibold" color="gray-800">
        {t('floor_information')}
      </Typography>
      <FloorGrid dataSource={floor?.data} pagerModel={floorPagerModel} onPageChange={handleFloorPageChange} />
      <Typography fontWeight="semibold" color="gray-800">
        {t('ho_information')}
      </Typography>
      <HoGrid dataSource={ho?.data} pagerModel={hoPagerModel} onPageChange={handleHoPageChange} />
    </>
  )
}

export default FloorAndHoInfo
