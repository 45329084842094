import { FC, useState } from 'react'
import { useOfficeLesseeDataV1BuildingsOfficeLesseeGet } from '~/libs/apis/data/buildings-office/buildings-office'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import LesseeInfoGrid from '~/templates/place/detail/lessee/LesseeInfoGrid'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'

const PAGE_SIZE = 5
const OfficeLesseeInfo: FC = () => {
  const { uid: raId } = useDetailInfo()

  const [pageNum, setPageNum] = useState(1)

  const { lastUpdateParams } = useLastUpdateDate('quarter')
  const { data, isLoading } = useOfficeLesseeDataV1BuildingsOfficeLesseeGet(
    { raId, pageNum, pageSize: PAGE_SIZE, year: lastUpdateParams.year, quarter: lastUpdateParams.quarter! },
    {
      swr: { keepPreviousData: true },
    },
  )

  const handlePageChange = (page: number) => setPageNum(page)

  return (
    <LesseeInfoGrid
      dataSource={data?.data}
      pageModel={data?.page}
      onPageChange={handlePageChange}
      isLoading={isLoading}
    />
  )
}

export default OfficeLesseeInfo
