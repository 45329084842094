/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.4.3
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  GetKakaoLesseeDataV1ExternalKakaoLesseeGetParams
} from '../model/getKakaoLesseeDataV1ExternalKakaoLesseeGetParams'
import type {
  GetNaverLesseeDataV1ExternalNaverLesseeGetParams
} from '../model/getNaverLesseeDataV1ExternalNaverLesseeGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  SchemasCommonExternalLesseePagingOut
} from '../model/schemasCommonExternalLesseePagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 임차인 정보 - 네이버 (사용 테이블: ra_v2.mv_stacking_plan_naver)
 */
export const getNaverLesseeDataV1ExternalNaverLesseeGet = (
    params: GetNaverLesseeDataV1ExternalNaverLesseeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasCommonExternalLesseePagingOut>(
      {url: `/data/v1/external/naver/lessee`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetNaverLesseeDataV1ExternalNaverLesseeGetKey = (params: GetNaverLesseeDataV1ExternalNaverLesseeGetParams,) => [`/data/v1/external/naver/lessee`, ...(params ? [params]: [])] as const;

    
export type GetNaverLesseeDataV1ExternalNaverLesseeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getNaverLesseeDataV1ExternalNaverLesseeGet>>>
export type GetNaverLesseeDataV1ExternalNaverLesseeGetQueryError = HTTPValidationError

/**
 * @summary 임차인 정보 - 네이버 (사용 테이블: ra_v2.mv_stacking_plan_naver)
 */
export const useGetNaverLesseeDataV1ExternalNaverLesseeGet = <TError = HTTPValidationError>(
 params: GetNaverLesseeDataV1ExternalNaverLesseeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getNaverLesseeDataV1ExternalNaverLesseeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetNaverLesseeDataV1ExternalNaverLesseeGetKey(params) : null);
  const swrFn = () => getNaverLesseeDataV1ExternalNaverLesseeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 임차인 정보 - 카카오 (사용 테이블: ra_v2.mv_stacking_plan_kakao)
 */
export const getKakaoLesseeDataV1ExternalKakaoLesseeGet = (
    params: GetKakaoLesseeDataV1ExternalKakaoLesseeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasCommonExternalLesseePagingOut>(
      {url: `/data/v1/external/kakao/lessee`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetKakaoLesseeDataV1ExternalKakaoLesseeGetKey = (params: GetKakaoLesseeDataV1ExternalKakaoLesseeGetParams,) => [`/data/v1/external/kakao/lessee`, ...(params ? [params]: [])] as const;

    
export type GetKakaoLesseeDataV1ExternalKakaoLesseeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getKakaoLesseeDataV1ExternalKakaoLesseeGet>>>
export type GetKakaoLesseeDataV1ExternalKakaoLesseeGetQueryError = HTTPValidationError

/**
 * @summary 임차인 정보 - 카카오 (사용 테이블: ra_v2.mv_stacking_plan_kakao)
 */
export const useGetKakaoLesseeDataV1ExternalKakaoLesseeGet = <TError = HTTPValidationError>(
 params: GetKakaoLesseeDataV1ExternalKakaoLesseeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getKakaoLesseeDataV1ExternalKakaoLesseeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetKakaoLesseeDataV1ExternalKakaoLesseeGetKey(params) : null);
  const swrFn = () => getKakaoLesseeDataV1ExternalKakaoLesseeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

